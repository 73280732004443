/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
comeos-dossier-boost {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: #252525;
  line-height: 1em;
  font-size: 14px;
}
comeos-dossier-boost .inner {
  background-color: #19d1c6;
  position: relative;
  display: flex;
  align-items: center;
  /*border-top-left-radius:5px;
        border-top-right-radius:5px;*/
}
comeos-dossier-boost .inner .close {
  margin-left: auto;
  text-align: center;
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 20px;
  color: #FFFFFF;
}
comeos-dossier-boost .inner .message {
  display: inline-flex;
  align-content: center;
  margin-right: 20px;
  color: #FFFFFF;
}
comeos-dossier-boost .inner .information {
  color: #FFFFFF;
  font-size: 20px;
  height: 100%;
  width: 20px;
  margin-right: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
